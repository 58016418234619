


























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Picker from '@/components/Picker.vue';

@Component({
  components: {
    Picker
  }
})
export default class Slider extends Vue {}
