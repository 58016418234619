var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Picker',{attrs:{"data":[
      { name: 'Lorem Ipsum', price: 7, id: Math.random() },
      { name: 'Lorem Ipsum', price: 8, id: Math.random() },
      { name: 'Lorem Ipsum', price: 8, id: Math.random() },
      { name: 'Lorem Ipsum', price: 9, id: Math.random() },
      { name: 'Lorem Ipsum', price: 9, id: Math.random() },
      { name: 'Lorem Ipsum', price: 9, id: Math.random() },
      { name: 'Lorem Ipsum', price: 10, id: Math.random() },
      { name: 'Lorem Ipsum', price: 11, id: Math.random() },
      { name: 'Lorem Ipsum', price: 14, id: Math.random() },
      { name: 'Lorem Ipsum', price: 14, id: Math.random() },
      { name: 'Lorem Ipsum', price: 15, id: Math.random() },
      { name: 'Lorem Ipsum', price: 1, id: Math.random() },
      { name: 'Lorem Ipsum', price: 1, id: Math.random() },
      { name: 'Lorem Ipsum', price: 3, id: Math.random() },
      { name: 'Lorem Ipsum', price: 2, id: Math.random() }
    ],"comparateKey":'price',"city":'Liège'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }